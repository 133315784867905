import { useEffect } from "react";

function Home() {
  useEffect(() => {
    // Redirect to the Zoom website
    window.location.href = "https://www.zoom.us";
  }, []);

  return null; // Nothing is rendered on the page
}

export default Home;
